import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import '../assets/css/App.css';
import { TextInput, Text, Button, PasswordInput, LoadingOverlay, Checkbox, Paper } from '@mantine/core';
import { useForm, useScrollIntoView, useWindowScroll } from '@mantine/hooks';
import useAuth from '../auth/useAuth';
import authApi from '../api/auth'
import { AuthToken } from "../entity/AuthToken";
import { Link, useNavigate } from "react-router-dom";
import img2 from "../assets/images/img_public_2.jpg"
import img3 from "../assets/images/img_public_3.jpg"
import logo_fm from "../assets/images/logo_FM.png"
import logo_fm_horiz from "../assets/images/logo_FM_horiz.png"
import connect from "../assets/images/triangleConnect.png"
import illus_1 from "../assets/images/illus-header1-1.png"
import illus_2 from "../assets/images/illus-header1-2.png"
import illus_3 from "../assets/images/illus-header1-3.png"
import illus_4 from "../assets/images/illus-header2-1.png"
import illus_5 from "../assets/images/illus-header2-2.png"
import illus_6 from "../assets/images/logo_FM-white2.svg"
import logo_fm_white from "../assets/images/logo_FM-white.png"
import president from "../assets/images/president.png"
import qualiopi from "../assets/images/qualiopi.webp"
import aausers from "../assets/images/users.svg"
import users from '../api/users';
import bird from "../assets/images/bird.svg"
import money from "../assets/images/money.svg"
import hands from "../assets/images/hands.svg"
import gui from "../assets/images/gui.svg"
import prodemial from "../assets/Prodemial-Image.png"
import { RiAccountCircleFill, GoKey, VscArrowDown } from "react-icons/all";
import { IconContext } from "react-icons";
import { GrLinkTop } from "react-icons/all";
// @ts-ignore
import CountUp from 'react-countup';

import {
    AiOutlineArrowDown,
    AiOutlineArrowUp,
    BiEuro,
    FaUsers,
    FiLogIn,
    GiShakingHands,
    IoClose,
    SiFreelancer
} from "react-icons/all";
import Request from "../api/request";
import useApi from "../hooks/useApi";

function Home() {
    const auth = useAuth();

    const [loginFailed, setLoginFailed] = useState(false);
    const { data, request } = useApi(users.me);
    const [panelOpen, setPanelOpen] = useState(false);
    const navigation = useNavigate();
    const [loading, setLoading] = useState(false);
    const [scroll, scrollTo] = useWindowScroll();
    const [checked, setChecked] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [nbAdhérents,setNbAdhérents] = useState(Number)
    const ref2 = useRef<HTMLDivElement>(null);
    const [adherents, setAdherents] = useState(0);
    // @ts-ignore
    const executeScroll = () => ref.current.scrollIntoView()
    // @ts-ignore
    const executeScroll2 = () => ref2.current.scrollIntoView()

    const handleSubmit = async (values: any) => {
        setLoading(true)
        const response = await authApi.login(values.email, values.password);
        console.log(response)
        if (!response.ok) {
            setLoginFailed(true);
            setLoading(false)
            return;
        }
        setLoginFailed(false);
        // @ts-ignore
        const token = new AuthToken(response?.data?.token, response?.data?.refresh_token)
        await auth.logIn(token)
        request().then(res => {
            if (auth.isAuthenticated()) {
                if(res?.data?.roles[0] == "ROLE_GUEST")
                    navigation('/auth/workshop', { replace: true })
                else if(res?.data?.roles[0] == "ROLE_EXPERT_ACCOUNTANT")
                    navigation('/auth/expert-comptable', { replace: true })
                else
                    navigation('/auth/home', { replace: true })
            }
        })
        setLoading(false)
    }

    useEffect(() => {
        request().then(res => {
            if (auth.isAuthenticated()) {
                if(res?.data?.roles[0] == "ROLE_GUEST")
                    navigation('/auth/workshop', { replace: true })
                else if(res?.data?.roles[0] == "ROLE_EXPERT_ACCOUNTANT")
                    navigation('/auth/expert-comptable', { replace: true })
                else
                    navigation('/auth/home', { replace: true })
            }
        })
        Request.get('adherents/roles').then((res:any) => {
            setNbAdhérents(res.data.adherents)
        })
    }, []);

    // @ts-ignore
    function diff_years(dt2, dt1)
    {
        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60 * 24);
        return Math.abs(Math.round(diff/365.25));
    }





    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },

        validationRules: {
            email: (value) => value.length >= 1,
            password: (value) => value.length >= 1,
        },
    });


    return (
        <>
            <LoadingOverlay visible={loading} />
            <div onClick={() => setPanelOpen(true)} className={panelOpen ? 'hidden' : 'block'}>
                <img src={connect}
                    className="fixed block lg:hidden bottom-0 border-0 right-0 transform z-50 w-28 h-28"
                    alt="" />
                <FiLogIn className="fixed block lg:hidden bottom-6 font-bold right-6 z-50 text-white" size="25" />
                <span
                    className="fixed block lg:hidden bottom-1 font-bold right-1 z-50 text-xxs text-white">Je me connecte</span>
            </div>
            <div onClick={() => setPanelOpen(false)} className={panelOpen ? 'block' : 'hidden'}>
                <img src={connect}
                    className="fixed block lg:hidden bottom-0 border-0 right-0 transform z-50 w-28 h-28"
                    alt="" />
                <IoClose className="fixed block lg:hidden bottom-6 font-bold right-6 z-50 text-white" size="25" />
                <span
                    className="fixed block lg:hidden bottom-1 font-bold right-6 z-50 text-xxs text-white">Fermer</span>
            </div>
            <div ref={ref2} />

            <div
                className={panelOpen ? 'block' : 'hidden' + ' flex'}
            >
                <div
                    className="fixed flex items-center justify-center h-screen lg:hidden z-40 bg-white right-0 top-0 w-80 shadow px-4 py-8 ">
                    <div>
                        <img className="w-2/3 mx-auto" src={logo_fm_horiz} alt="" />
                        <div className="container mx-auto my-auto py-4">
                            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                                <div className="flex flex-col mx-5">
                                    <div className="flex-1">
                                        <TextInput
                                            className='mb-4'
                                            required
                                            placeholder="Identifiant"
                                            error={form.errors.email && 'Email invalide'}
                                            value={form.values.email}
                                            onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <PasswordInput
                                            required
                                            placeholder="Mot de passe"
                                            error={form.errors.password && 'Mot de passe invalide'}
                                            value={form.values.password}
                                            onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                                        />
                                    </div>
                                    <div>
                                        <button className="bg-blueButton font-bold text-sm w-full mt-4 p-1 rounded text-white" type="submit">OK</button>
                                        <Checkbox
                                            className='mt-4 text-xs'
                                            checked={checked}
                                            onChange={(event) => setChecked(event.currentTarget.checked)}
                                            label="Se souvenir de moi" />
                                    </div>
                                </div>
                                {loginFailed && <p className="text-red-700">
                                    Identifiant ou Mot de passe invalide
                                </p>}
                            </form>
                            <div className="flex flex-col mt-5 mr-5">
                                <span className="mt-20">
                                    <Link to="/reset/password">
                                        <span className="text-xs lg:text-sm">Identifiant ou mot de passe oublié ?</span>
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col-reverse lg:flex-row lg:h-screen">
                <div className="flex-1 lg:relative bg-pack-train bg-top lg:bg-center bg-cover lg:bg-cover bg-no-repeat">
                    <img src={illus_1} className="absolute hidden lg:block top-3 left-0 w-2/4 mix-blend-hard-light"
                        alt="" />
                    <img src={illus_2} className="absolute hidden lg:block bottom-2 right-14 w-2/12 mix-blend-hard-light"
                        alt="" />
                    <img src={illus_3} className="absolute hidden lg:block bottom-0 right-0 transform rotate-180"
                        alt="" />
                    <div className="lg:mx-14 mt-20 mb-32">
                        <div className="mx-5 lg:mt-80 bg-gray-100 opacity-90 rounded px-4 lg:px-24 py-6 text-center shadow-md">
                            <h1 className="text-sm lg:text-xl font-bold uppercase">Découvrir l'association</h1>
                            <p className="text-gray-800 text-xs lg:text-sm">Pour découvrir nos activités et notre
                                fonctionnement, des
                                demi-journées d'information sont organisées </p>
                            <div className="mt-2 lg:mt-4">
                                <Link to="/register">
                                    <Button size="lg" color="pink">Je m'inscris</Button>
                                </Link>
                                <span
                                    className=" block text-center mx-auto text-gray-600 text-xs mt-1">Première connexion</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div className=" hidden lg:block flex-1">
                    <div className="container mx-auto px-4 py-4">
                        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                            <div className="flex">
                                <div className="flex-1 px-2">
                                    <TextInput
                                        required
                                        icon={<RiAccountCircleFill />}
                                        placeholder="Identifiant"
                                        error={form.errors.email && 'Email invalide'}
                                        value={form.values.email}
                                        onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                                    />
                                </div>
                                <div className="flex-1 px-2">
                                    <PasswordInput
                                        required
                                        icon={<GoKey />}
                                        placeholder="Mot de passe"
                                        error={form.errors.password && 'Mot de passe invalide'}
                                        value={form.values.password}
                                        onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                                    />
                                </div>
                                <div className="flex px-2">
                                    <button className="mt-auto bg-blueButton font-bold text-sm h-full w-auto p-1 rounded text-white" type="submit">OK</button>
                                </div>
                            </div>
                            {loginFailed && <p className="text-red-700 m-2 text-sm">
                                Identifiant ou Mot de passe invalide
                            </p>}
                        </form>
                        <div className="flex mt-5 mr-5">
                            <span className="mr-auto">
                                <Checkbox checked={checked}
                                    onChange={(event) => setChecked(event.currentTarget.checked)}
                                    label="Se souvenir de moi" />
                            </span>
                            <span className="ml-auto">
                                <Link to="/reset/password">
                                    <span className="text-xs lg:text-sm">Identifiant ou Mot de passe oublié ?</span>
                                </Link>
                            </span>
                        </div>
                        <div className="container mx-auto px-10 lg:py-10 lg:pt-40">
                            <img className="w-2/5 mx-auto" src={logo_fm} alt="" />
                            <h1 className="text-center mt-10 lg:mt-5 text-5xl lg:text-7xl text-gray-800 font-bold ">BIENVENUE</h1>
                        </div>
                        <AiOutlineArrowDown onClick={executeScroll}
                            className="text-center hidden lg:block justify-center lg:mx-auto mt-10 2xl:mt-52"
                            size="70" />
                    </div>
                </div>
                <div className="container block lg:hidden mx-auto text-center px-10 py-4 lg:py-10 lg:pt-40 mb-12">
                    <img className="w-2/4 mx-auto mt-10" src={logo_fm} alt="" />
                    <h1 className="text-center mt-5 lg:mt-5 text-4xl lg:text-7xl text-gray-800 font-bold ">BIENVENUE</h1>
                    <span className="text-sm text-center justify-center mx-auto">Vous êtes sur le portail de l'Association <br /> Nationale FORMAN, loi 1901</span>
                </div>
            </div>
            <div onClick={executeScroll} className="block lg:hidden mx-10 my-3">
                <IconContext.Provider value={{ color: "gray", className: "global-class-name block lg:hidden ml-5" }}>
                    <div>
                        <VscArrowDown size={30} />
                    </div>
                    <span className="text-xs">En savoir plus </span>
                </IconContext.Provider>
            </div>
            <div className="flex flex-col lg:flex-row ">
                <div className="flex-1 my-auto ">
                    <div className="container lg:my-10 mx-auto px-2 md:px-10 lg:px-24">
                        <p className="text-sm text-center md:text-left lg:pl-8 mt-20">
                            <h2 className=' text-2xl text-gray-600 mb-4'>L'association FORMAN c'est,</h2>
                            <div ref={ref} />
                            " Une association à but non lucratif créée en 2009 regroupant plus de 500 consultants du réseau Prodémial. <img className="h-12 mr-auto mt-5 mx-auto mb-8 md:mb-0"
                            src={prodemial} alt=""/>

                            <br />
                            <br />
                            Un parcours de formation et de professionnalisation innovant et performant s’appuyant sur des valeurs de partage, de transmission, d’esprit d’équipe.

                            <br />
                            <br />
                            Des méthodes exclusives pour progresser individuellement et collectivement. Une offre de formation complète certifiée QUALIOPI.

                            <a target="_blank" href="https://qualiopi.certif-icpf.org/obtenir-qualiopi?utm_source=google&utm_medium=cpc&utm_campaign=trafic_qualiopi&utm_content=optimized_to_homepage&gclid=CjwKCAiAxJSPBhAoEiwAeO_fPyp0wxSx4PxHHvgh-WGVB0cVZku2DLpVmBcnfXqXnrrGsaK-_yAF3RoCkWkQAvD_BwE">
                                <img className="h-16 mr-auto mt-5 mx-auto mb-8 md:mb-0" src={qualiopi} alt="" />
                            </a>
                            <br />
                            <br />
                            La certification qualité a été délivrée aux titres des catégories d’actions suivantes : <span className="font-bold">actions de formation</span>.
                        </p>
                    </div>
                </div>
                <div className="flex-1 bg-img2 w-auto bg-cover bg-no-repeat relative">
                    <img src={illus_3} className="absolute top-0 left-0  hidden lg:block w-1/12 transform " alt="" />
                    <img src={illus_4}
                        className="absolute top-0 right-0 bottom-0  mix-blend-hard-light w-48 lg:w-4/12 "
                        alt="" />
                    <div className="m-48" />
                </div>
            </div>
            { /*

            <div className="container mt-16 lg:mt-32 relative">
                <img src={illus_1} className="absolute hidden md:block left-0 top-0 w-1/6 " alt="" />
                <div className="px-8 mx-auto">
                    <h1 className="text-center text-pink-500 text-3xl">Le mot du président</h1>
                    <img className="justify-center mx-auto block lg:hidden mt-5" src={president} alt="président" />
                    <p className="italic block lg:hidden mt-5 text-md md:text-sm">
                        <div className="flex">
                            <img src={gui} className="w-12 mb-auto flex" alt="" />
                            <span className="flex-auto ml-2">Après une expérience dans le contrôle de gestion dans un grand groupe informatique,
                                        j’ai décidé, en 2003 de rejoindre le réseau Prodémial <br />
                                        pour embrasser le monde des entrepreneurs et le conseil patrimonial.
                            </span>
                        </div>
                        <span>
                                    Fort de plusieurs succès dans le développement de mon activité et de mon équipe, j’ai souhaité créer en 2009 un parcours de formations pratiques en complément de celles proposées par Prodémial pour favoriser la professionnalisation de mon équipe et accélérer mon développement.</span> <br />
                        L’association FORMAN a vu le jour en septembre 2009 pour regrouper l’ensemble des consultants Prodémial de mon équipe autour de valeurs communes, partagées par l’ensemble des adhérents : le don, la bienveillance, la transmission … et la réussite de chacun !
                        Depuis 2018, FORMAN c'est aussi un centre de formation au savoir-faire reconnu, et l'une des premières écoles de France certifiée Qualiopi.
                        En plus de 10 ans, l'association a prouvé son efficacité : nos adhérents connaissent une croissance continue de leur activité.
                        en hausse constante et un nombre toujours plus important de clients. Notre association compte aujourd'hui plus de 500 membres tournés vers le seul intérêt clients.
                        Notre ambition est d'accompagner chaque adhérent à développer une grande et belle entreprise en gestion de patrimoine tout en ayant la possibilité d'enseigner cette matière.
                        Nous sommes animés par une forte ambition : continuer à fédérer et professionnaliser l’ensemble de nos adhérents au service de nos clients.
                        Je vous souhaite un chemin long et couronné de succès.
                        <span className='flex'>Bienvenue chez FORMAN ! <img src={gui} className="w-5 ml-3 reverse-image" alt="" /></span>
                    </p>
                    <div className="flex container mt-10">
                        <div className="flex-1 mx-auto justify-end hidden lg:block">
                            <img className="justify-end ml-auto mr-10" src={president} alt="président" />
                        </div>
                        <div className="flex-1 mx-auto hidden lg:block">
                            <p className="italic text-sm leading-relaxed">
                                <div className="flex">
                                    <img src={gui} className="w-20 flex" alt="" />
                                    <span className="flex-auto ml-2">Après une expérience dans le contrôle de gestion dans un grand groupe informatique, <br />
                                        j’ai décidé, en 2003 de rejoindre le réseau Prodémial <br />
                                        pour embrasser le monde des entrepreneurs et le conseil patrimonial.</span>
                                    <br/>
                                </div>
                                <span>
                                    Fort de plusieurs succès dans le développement de mon activité et de mon équipe, j’ai souhaité créer en 2009 un parcours de formations pratiques en complément de celles proposées par Prodémial pour favoriser la professionnalisation de mon équipe et accélérer mon développement.</span> <br />
                                L’association FORMAN a vu le jour en septembre 2009 pour regrouper l’ensemble des consultants Prodémial de mon équipe autour de valeurs communes, partagées par l’ensemble des adhérents : le don, la bienveillance, la transmission … et la réussite de chacun !
                                Depuis 2018, FORMAN c'est aussi un centre de formation au savoir-faire reconnu, et l'une des premières écoles de France certifiée Qualiopi.
                                En plus de 10 ans, l'association a prouvé son efficacité : nos adhérents connaissent une croissance continue de leur activité.
                                en hausse constante et un nombre toujours plus important de clients. Notre association compte aujourd'hui plus de 500 membres tournés vers le seul intérêt clients.
                                Notre ambition est d'accompagner chaque adhérent à développer une grande et belle entreprise en gestion de patrimoine tout en ayant la possibilité d'enseigner cette matière.
                                Nous sommes animés par une forte ambition : continuer à fédérer et professionnaliser l’ensemble de nos adhérents au service de nos clients.
                                Je vous souhaite un chemin long et couronné de succès.
                                <span className='flex'>Bienvenue chez FORMAN ! <img src={gui} className="w-5 ml-3 reverse-image" alt="" /></span>
                                <span>
                                    Cédric PAYRAUDEAU       <br/>
Président et fondateur de l’association FORMAN
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
                */}
            <div className="bg-gradient-to-r from-gradientHome1 to-gradientHome2 relative">
                <img src={illus_3} className="absolute bottom-0 transform -rotate-90 left-0 w-12 mix-blend-hard-light"
                    alt="" />
                <img src={illus_3} className="absolute top-0 transform left-0 w-1/6 opacity-10" alt="" />
                <img src={illus_3} className="absolute top-6 transform right-0 w-1/6 opacity-10" alt="" />
                <img src={illus_3} className="absolute bottom-0 transform -rotate-90 right-0 w-1/12 opacity-10" alt="" />
                <div className="container mx-auto my-auto mt-20 p-5 lg:p-10">
                    <h2 className="text-3xl text-center text-white font-bold">Nous découvrir Forman c’est aujourd’hui</h2>
                    <div className="lg:m-20 my-auto mb-0">
                        <div className="flex flex-col lg:flex-row my-auto">
                            <div className="flex-1 my-auto text-center mt-10">
                                <p className="text-lg text-gray-200">3 Séminaires de professionnalisation certifiés QUALIOPI</p>
                            </div>
                            <div className="flex-1 my-auto text-center mt-10">
                                <p className="text-lg text-gray-200">480 ateliers</p>
                            </div>
                            <div className="flex-1 text-center mt-10">
                                <p className="text-lg text-gray-200">32 modules</p>
                            </div>
                            <div className="flex-1 text-center mt-10">
                                <p className="text-lg text-gray-200">1 Ecole des formateurs</p>
                            </div>
                            <div className="flex-1 text-center mt-10">
                                <p className="text-lg text-gray-200">122 formateurs</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-1 block lg:hidden bg-img3 bg-cover h-72 relative">
                <img src={illus_5} className="absolute top-0 bottom-0 w-1/4 right-0 mix-blend-hard-light" alt="" />
                <img src={illus_3} className="absolute top-0 left-0 w-1/6 w-1/4 mix-blend-hard-light" alt="" />
            </div>
            <div className="flex flex-col-reverse lg:flex-row">
                <div className="flex-1 bg-img3 bg-cover hidden lg:block h-96 relative">
                    <img src={illus_5} className="absolute top-0 bottom-0 w-1/4 right-0 mix-blend-hard-light" alt="" />
                    <img src={illus_3} className="absolute top-0 left-0 w-1/6 w-1/4 mix-blend-hard-light" alt="" />
                </div>
                <div className="flex-1 flex items-center justify-center">
                    <div className=" container text-center justify-center items-center mx-auto">
                        <Link className="flex justify-center items-center" to="/register">
                            <Button className="hidden lg:block" color="pink" size="lg">
                                Je participe à ma première réunion d'informations
                            </Button>
                            <Button className="block lg:hidden my-10 h-20" color="pink" size="lg">
                                <div className="text-base mx-2">
                                    Je participe à ma première
                                    réunion<br /> d'informations
                                </div>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>

            <IconContext.Provider value={{ color: "gray", className: "block mx-auto text-center lg:hidden justify-center my-10" }}>
                <div>
                    <GrLinkTop size={40} />
                </div>
            </IconContext.Provider>

            <div className="bg-gray-500 relative">
                <img src={illus_3} className="absolute top-0 transform left-0  lg:w-1/12 opacity-10" alt="" />
                <img src={illus_6}
                    className="absolute bottom-24 lg:bottom-10 transform right-12 lg:right-48 lg:w-46"
                    alt="" />
                <div className="container mx-auto p-5">
                    <div className="flex flex-col lg:flex-row items-center lg:items-start space-y-10 md:space-y-0 py-10">
                        <div className="flex-1">
                            <img className="w-1/2 mx-auto" src={logo_fm_white} alt="logo-forman" />
                        </div>
                        <div className="flex-1 mt-3 lg:mt-0 text-center lg:text-center">
                            <span className="text-white text-4xl font-bold">
                                <CountUp
                                    end={nbAdhérents}
                                    duration={2}
                                    className={"text-white text-4xl font-bold"}
                                /> +</span>
                            <br />
                            <span className="text-gray-200 text-sm">Adhérents</span>
                        </div>
                        <div className="flex-1 mt-3 lg:mt-0 text-center lg:text-center">
                            <span className="text-white text-4xl font-bold"><CountUp
                                end={diff_years(new Date(2009,9,10), new Date())}
                                duration={2}
                                className={"text-white text-4xl font-bold"}
                            /></span>
                            <br />
                            <span className="text-gray-200 text-sm">Ans</span>
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row my-3 items-center lg:items-start justify-center">
                        <div className="flex mx-1 mt-3">
                            <Link className="text-gray-100 hover:underline" to="/contact">
                                Contactez-nous
                            </Link>
                        </div>
                        <div className="flex mx-1 mt-3">
                            <Link className="text-gray-100 hover:underline" to="/cnil">
                                CNIL
                            </Link>
                        </div>
                        <div className="flex mx-1 mt-3">
                            <Link className="text-gray-100 hover:underline" to="/mentions-legales">
                                Mentions Légales
                            </Link></div>
                    </div>
                    <span
                        className=" flex justify-center text-gray-200 mb-5 text-xs">Développé par
                        <a className='mx-1' title='Agence Digitale Innovante » Solutions, applications, sites' target="_blank" href="https://www.appyness.fr/">Appyness</a>
                        et designé par
                        <a className='mx-1' title='Agence de création graphique et digitale à Bordeaux' target="_blank" href="https://caleis.fr">Caléis</a></span>
                </div>
            </div>


            {
                scroll.y > 0 && (
                    <div className="fixed hidden lg:block p-5 bottom-0 w-64 justify-center right-0">
                        <div className="bg-gray-100 mx-auto rounded bg-opacity-75 px-8 py-4 justify-center">
                            <h1 className="text-xl uppercase text-right">Découvrir l'association</h1>
                            <Link className=" flex" to="/register">
                                <Button fullWidth size="md" color="pink">Je m'inscris</Button>
                            </Link>
                            <div className="flex justify-center items-center mx-auto">
                                <span
                                    className="text-center mx-auto  text-gray-600 text-xs mt-1"> Première connexion</span>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
        ;
}

export default Home;